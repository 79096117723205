var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "row push"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-lg-8 col-xl-5"
  }, [_c('FormGroup', {
    attrs: {
      "id": "legalName",
      "error": _vm.error['legalName'],
      "label": "Name",
      "type": "text"
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', _vm.form);
      }
    },
    model: {
      value: _vm.form.legalName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "legalName", $$v);
      },
      expression: "form.legalName"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "type",
      "error": _vm.error['type'],
      "label": "Category",
      "type": "select",
      "options": _vm.entityTypes
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }), _vm.entityOptions.length ? _c('FormGroup', {
    attrs: {
      "id": "entityId",
      "error": _vm.error['parentEntityId'],
      "options": _vm.entityOptions,
      "type": "select2",
      "label": "Parent Entity",
      "config": {
        allowClear: true
      },
      "placeholder": "Select a parent entity (optional)"
    },
    on: {
      "input": _vm.onSelectEntity
    },
    model: {
      value: _vm.form.parentEntityId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "parentEntityId", $$v);
      },
      expression: "form.parentEntityId"
    }
  }) : _vm._e()], 1)]), _vm._m(1), _c('div', {
    staticClass: "row push"
  }, [_vm._m(2), _c('div', {
    staticClass: "col-lg-8 col-xl-5"
  }, [_c('AddressForm', {
    attrs: {
      "hide-lat-lng": ""
    },
    model: {
      value: _vm.form.billingAddress,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "billingAddress", $$v);
      },
      expression: "form.billingAddress"
    }
  })], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-4"
  }, [_c('p', {
    staticClass: "text-muted"
  }, [_vm._v("Enter your details.")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', {
    staticClass: "content-heading pt-0"
  }, [_c('i', {
    staticClass: "fa fa-fw fa-info text-muted mr-1"
  }), _vm._v(" Billing Address")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-4"
  }, [_c('p', {
    staticClass: "text-muted"
  }, [_vm._v("Enter the billing address associated with this entity. This is shown on invoice validation sheets.")])]);
}]

export { render, staticRenderFns }