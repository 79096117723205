<template>
  <div>
    <div class="row push">
      <div class="col-lg-4">
        <p class="text-muted">Enter your details.</p>
      </div>
      <div class="col-lg-8 col-xl-5">
        <FormGroup id="legalName" v-model="form.legalName" :error="error['legalName']" label="Name" type="text" @input="$emit('input', form)" />

        <FormGroup id="type" v-model="form.type" :error="error['type']" label="Category" type="select" :options="entityTypes" />

        <FormGroup
          v-if="entityOptions.length"
          id="entityId"
          v-model="form.parentEntityId"
          :error="error['parentEntityId']"
          :options="entityOptions"
          type="select2"
          label="Parent Entity"
          :config="{ allowClear: true }"
          placeholder="Select a parent entity (optional)"
          @input="onSelectEntity"
        />
      </div>
    </div>

    <h2 class="content-heading pt-0"><i class="fa fa-fw fa-info text-muted mr-1"></i> Billing Address</h2>

    <div class="row push">
      <div class="col-lg-4">
        <p class="text-muted">Enter the billing address associated with this entity. This is shown on invoice validation sheets.</p>
      </div>
      <div class="col-lg-8 col-xl-5">
        <AddressForm v-model="form.billingAddress" hide-lat-lng />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import AddressForm from './AddressForm';
import Form from './Form';
import FormGroup from '../FormGroup';

export default {
  name: 'EntityForm',
  components: {
    AddressForm,
    FormGroup
  },
  extends: Form,
  props: {
    refresh: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      modals: {
        loading: false
      },
      selectedEntityId: '',
      searchTerm: ''
    };
  },
  computed: {
    ...mapGetters({
      entities: 'entity/listStructure',
      entity: 'entity/entity',
      loadingAction: 'entity/loadingAction',
      companies: 'user/companies'
    }),
    filteredCompanies() {
      return this.companies
        .filter(c => c._id !== this.entity.companyId)
        .map(c => {
          return { label: c.name, value: c._id };
        });
    },
    entityOptions() {
      if (!this.entities.length) return [];

      const extractChildren = (entity, level = 1) =>
        (entity.children || []).reduce(
          (acc, entity) => [...acc, { label: entity.legalName, value: entity._id, level }, ...extractChildren(entity, level + 1)],
          []
        );

      return this.entities.reduce((acc, entity) => {
        return [...acc, { label: entity.legalName, value: entity._id, level: 0 }, ...extractChildren(entity)];
      }, []);
    },
    entityTypes() {
      return [
        {
          label: 'Private Company',
          value: 'company'
        },
        {
          label: 'Fund',
          value: 'fund'
        },
        {
          label: 'Charity',
          value: 'charity'
        },
        {
          label: 'School/University',
          value: 'school/univsersity'
        },
        {
          label: 'Council',
          value: 'council'
        },
        {
          label: 'Other',
          value: 'other'
        }
      ];
    },
    companyTypes() {
      return [
        {
          label: 'Limited Company',
          value: 'Limited Company'
        },
        {
          label: 'Public Limited Company',
          value: 'Public Limited Company'
        },
        {
          label: 'Non-profit',
          value: 'Non-profit'
        }
      ];
    },
    currency() {
      return [
        {
          label: 'GBP',
          value: 'gbp'
        },
        {
          label: 'USD',
          value: 'usd'
        },
        {
          label: 'EUR',
          value: 'eur'
        }
      ];
    }
  },
  mounted() {
    this.listEntities();
  },
  beforeMount() {
    if (!this.form.billingAddress) {
      this.form.billingAddress = {};
    }
  },
  methods: {
    ...mapActions({
      listEntities: 'entity/listStructure'
    }),
    onSelectEntity(value) {
      // Handle Select2 setting a value to "" when clearing the input
      if (!value) {
        this.form.parentEntityId = null;
      }
    }
  }
};
</script>
